import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../../container/main'
import SEO from '../../../components/seo'
import Container from '../../../components/container'
import ContentBannerWithCTA from '../../../components/content-banner-with-cta'
import { heroContainer, contentBlock } from './index.module.scss'
import { UseCaseBlock, UseCaseCategory, UseCaseHero, UseCasesContainer, UseCaseTeaser } from '../../../components/content-use-cases'
import ContentBlockSecondary from '../../../components/content-block-secondary'
import ContentBlockSmall from '../../../components/content-block-small'
import ContentBlockLargeWithGallery from '../../../components/content-block-large-gallery'
import Subheadline from '../../../components/content-subheadline'

const IndexPage = ({ data, location }) => (
  <MainContainer>
    <SEO title="Digital Employee Experience" />

    <Container fullWidth style={{ background: 'var(--primary)' }}>
      <UseCaseCategory title="Digital Employee Experience (DEX)" />
    </Container>

    <Container>
      <UseCaseHero
        title="Great people expect an excellent Digital Experience"
        description="Many companies are optimizing their employee experience to provide the best possible workplace experience. There is a lot of investment in modern office environments or corporate design promotional materials. But when it comes to the digital experience, many companies leave their employees alone, even though digital work is a challenge in todays app and data jungle. In competition for the greatest talents, it is more important than ever to offer a first-class digital experience. Especially in the age of remote work, you should offer your employees a digital headquarters for their entire day-to-day business."
        actionLinkText="Get started – It's free!"
        actionLinkIsExternal
        actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup&utm_content=home"
        media={<StaticImage src="../assets/images/home-hero.png" alt="Enterprise Productivity Hub" placeholder="blurred" />}
        demoButton
      />
    </Container>

    <Container style={{ padding: '80px 0 0' }}>
      <Subheadline
        headline="Your digital headquarters for satisfied employees"
        description="Provide your people with a great Digital Headquarters for all their daily business, no matter where they are."
      />
      <span style={{ display: 'block', marginTop: '-8px', fontSize: '16px', fontWeight: '600', color: 'var(--primary)' }}>
        Provide your people with a great Digital Headquarters for all their daily business, no matter where they are.
      </span>
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'MyDay',
            title: 'The entire daily business at a glance',
            description: `Employees receive notifications from daily business and relevant news in a central place and can easily optimize their workflow.`,
            media: <StaticImage src="../assets/images/basaas-2022-myday.png" alt="The entire daily business at a glance" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Search',
            title: 'Cross app search to find everything ',
            description: `Your staff can find information faster with an overarching search that searches your connected apps like Outlook, OneDrive, Sharepoint and more.`,
            media: <StaticImage src="../assets/images/basaas-2022-search.png" alt="Cross app search to find everything " placeholder="blurred" />,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Apps',
            title: 'All your apps in one solution',
            description: `Combine all apps into your digital headquarters and help your employees to find all their apps, content, or information quickly and easy.`,
            media: <StaticImage src="../assets/images/basaas-2022-apps.png" alt="All your apps in one solution" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'News',
            title: 'Improve your corporate communication',
            description: `Integrate your intranet solution or Microsoft Teams and Slack and significantly improve the way you reach your employees with corporate communication.`,
            media: <StaticImage src="../assets/images/basaas-2022-news-preview.png" alt="More reach for corporate communication" placeholder="blurred" />,
            comingSoon: true,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '160px 0 80px', marginTop: '160px' }}>
      <h2 style={{ fontSize: '24px', margin: '0 0 40px', fontWeight: 800 }}>Explore how to optimize your business for</h2>
      <UseCaseBlock location={location.pathname} />
    </Container>

    {/* BLOCK SECURITY  */}
    <Container style={{ background: 'var(--base-background)' }}>
      <ContentBlockSecondary
        headline="Enterprise productivity at the highest security level"
        description="The Basaas security concept is based on international best in class standards and EU requirements and guarantees the highest enterprise security level."
        // actionLinkText="Learn more"
        // actionLink="/tasks"
        item_01={
          <ContentBlockSmall
            key="1"
            headline="Fully GDPR compliant"
            description="Basaas is fully comply with European data security regulations including GDPR. For European companies, we guarantee that data storage takes place exclusively in the EU."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/gdpr-compliant.png" alt="Fully GDPR compliant" placeholder="blurred" />}
          />
        }
        item_02={
          <ContentBlockSmall
            key="2"
            headline="Proven enterprise security"
            description="At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/proven-enterprise-security.png" alt="Proven enterprise security" placeholder="blurred" />}
          />
        }
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        teamsCount
      }
    }
  }
`
